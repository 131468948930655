<template>
    <div>
        <b-row class="mt-2">
            <b-col sm="6" md="6">
                <span class="details-view-title">DELAYED TRANSFER</span>
                <div class="details-view-subtitle">List of delayed transfers that will mature within the month</div>
            </b-col>
            <b-col sm="6" md="4" offset-md="2" class="text-md-right">
                <b-input-group prepend="Show" append="/ Page">
                    <b-form-select :options="pageOptions" v-model="perPage" />
                </b-input-group>
            </b-col>
        </b-row>

        <b-table show-empty striped hover :items="items" :fields="fields" :current-page="currentPage"
            :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection" responsive>

            <template v-slot:cell(dispatchNo)="row">
                <div class="numFont">
                    <strong>{{ row.item.dispatchNo }}</strong>
                </div>
            </template>

            <template v-slot:cell(maturityDate)="row">
                {{ row.item.maturityDate ? getDisplayDateTime(row.item.maturityDate) : '-' }}
            </template>

            <template v-slot:cell(quantity)="row">
                <div class="numFont">
                    <strong>{{ row.item.quantity }}</strong>
                </div>
            </template>
        </b-table>

        <b-row class="my-1">
            <b-col md="8" sm="12">
                <span class="total-display">Total:
                    <span class="numFont">{{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
                    &nbsp;|&nbsp;
                    Total Delayed Transfers: <span class="numFont">{{ totalDelayedTransfers }}</span>
                </span>
            </b-col>
            <b-col md="4" sm="12">
                <b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
                    class="my-0" />
            </b-col>
        </b-row>

        <b-row class="mt-2" v-show="!isScanner && !isRetailer">
            <b-col lg="4" md="6" sm="12">
                <b-button variant="outline-primary" @click="goToDelayedTransferReport" class="mr-2">
                    View More
                </b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
// Utils
import { DateUtil } from '@/utils/dateutil';

// Others
import _ from 'lodash';

export default {
    name: 'delayed-transfer-summary',
    props: {
        dashboard: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            items: [],
            fields: [
                {
                    key: 'dispatchNo',
                    label: 'Dispatch No.',
                    sortable: true,
                },
                {
                    key: 'location',
                    label: 'Storage Location',
                    sortable: true,
                },
                {
                    key: 'accountNo',
                    sortable: true,
                },
                {
                    key: 'maturityDate',
                    label: 'Maturity Date',
                    sortable: true,
                },
                {
                    key: 'quantity',
                    label: 'Quantity',
                    sortable: true,
                },
            ],
            currentPage: 1,
            perPage: 5,
            totalRows: 0,
            pageOptions: [5, 10, 15, 25],
            sortBy: null,
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,

            isSuperAdmin: this.$store.getters.isSuperAdmin,
            isScanner: this.$store.getters.isScanner,
            isRetailer: this.$store.getters.isRetailer,
        }
    },
    watch: {
        dashboard: function () {
            this.items = this.dashboard.delayedTransfers;
            this.totalRows = this.items.length;
        }
    },
    computed: {
        totalDelayedTransfers() {
            return _.sumBy(this.items, o => { return o.quantity; });
        }
    },
    methods: {
        // UTILS
        getDisplayDateTime(date) {
            if (date) {
                return DateUtil.getFormattedDate(date);
            }
            return "-";
        },
        goToDelayedTransferReport() {
            if (!this.isSuperAdmin) {
                this.$router.push({ path: '/delayed-transfer-reports' });
            } else {
                this.$router.push({ path: '/admin/admin-delayed-transfer-reports' });
            }
        }
    }
}
</script>

<style scoped></style>